.slide {
  width: 100%;
  height: 100%;
  flex: 1;
  justify-content: center;
  display: flex;
  align-items: center;

  /* 
  &Justify {
    &Left {
      justify-content: flex-start;
    }

    &Center {
      justify-content: center;
    }

    &Right {
      justify-content: flex-end;
    }
  } */
}

.transitionWrapper {
  display: flex;
  height: 100%;
  background: black;
  justify-content: center;
  align-items: center;
}

.wrapperIframe {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 99;
}

.image {
  max-width: 100%;
  max-height: 100%;
  position: relative;
}

.video {
  max-width: 100%;
  height: 100%;
}

.collapsed {
  max-height: 0px;
  transition: max-height 0.25s ease-in;
}

.iframeWrapper {
  flex: 1;
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
}

.hideCursor {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  cursor: none;
  z-index: 100;
}
