.MuiToggleButtonGroup-root {
  background: none;
}

.MuiToggleButton-root.Mui-selected .MuiToggleButton-label {
  color: black;
}
.MuiToggleButton-root .MuiToggleButton-label {
  color: lightgray;
  font-size: 50px;
  padding: 20px;
}
.MuiToggleButtonGroup-root {
  border: 1px solid white;
}

.MuiToggleButton-root.Mui-selected {
  background-color: white !important;
}
