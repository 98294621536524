/*
 * @NOTE: Prepend a `~` to css file paths that are in your node_modules
 *        See https://github.com/webpack-contrib/sass-loader#imports
 */
/* @import '~@fortawesome/fontawesome-free/css/all.css';
@import '~animate.css/animate.min.css'; */

html {
  height: 100%;
}

body {
  position: relative;
  color: #6a6a6a;
  background-color: #000;
  font-family: "NunitoSans-Regular", "Open Sans", Arial, "Helvetica Neue",
    Helvetica, serif;
  overflow-y: hidden;
  width: 100%;
  height: 100%;
  margin: 0;
  user-select: none;
}

#root {
  height: 100%;
}

h2 {
  margin: 0;
  font-size: 2.25rem;
  font-weight: bold;
  letter-spacing: -0.025em;
  color: #6a6a6a;
}

p {
  font-size: 20px;
}

a {
  color: #fff;
  outline: none;
  text-decoration: none;
}

a:hover {
  opacity: 1;
  text-decoration: none;
  cursor: pointer;
}

progress[value] {
  -webkit-appearance: none;
  appearance: none;

  /* width: 100%; */
  height: 2px;
}

progress[value]::-webkit-progress-bar {
  background-color: #efeff4;
  border-radius: 4px;
}

progress[value]::-webkit-progress-value {
  background-color: #ec812b;
  border-radius: 24px;
  animation: animate-stripes 5s linear infinite;
}

progress[value]::-moz-progress-bar {
  background-color: #ec812b;
  border-radius: 24px;
  animation: animate-stripes 5s linear infinite;
}

@-webkit-keyframes animate-stripes {
  100% {
    background-position: -100px 0;
  }
}

@keyframes animate-stripes {
  100% {
    background-position: -100px 0;
  }
}

.fa-show {
  visibility: hidden;
}

#scroll-bar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

#scroll-bar::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}

#scroll-bar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}

.example-appear {
  opacity: 0.01;
}

.example-appear.example-appear-active {
  opacity: 1;
  transition: opacity 0.5s ease-in;
}

.my-node-enter {
  opacity: 0;
}
.my-node-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}
.my-node-exit {
  opacity: 1;
}
.my-node-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}

.reset-btn {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  outline: none;

  background: transparent;

  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;

  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;
}

/* Remove excess padding and border in Firefox 4+ */
.reset-btn::-moz-focus-inner {
  border: 0;
  padding: 0;
}
