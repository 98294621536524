@font-face {
    font-family: 'AlfaSlabOne'; 
    src: url('./assets/fonts/AlfaSlabOne-Regular/AlfaSlabOne-Regular.ttf'); 
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Myriad Pro'; 
    src: url('./assets/fonts/MyriadPro-Regular/MYRIADPRO-REGULAR.OTF'); 
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Blood Orange'; 
    src: url('./assets/fonts/Blood-Orange/BloodOrange.otf'); 
    font-weight: normal;
    font-style: normal;
  }

@font-face {
    font-family: 'Museo'; 
    src: url('./assets/fonts/Museo/Museo300-Regular-webfont.woff2') format('woff2'); 
    font-weight: normal;
    font-style: normal;
  }